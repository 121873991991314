import "leaflet/dist/leaflet.css";
import React, { useEffect, useState, useRef } from "react";
import { MapContainer, TileLayer, useMap, Pane } from "react-leaflet";
import "./MapDisplay.css";
import { ClipLoader } from "react-spinners";
import {
  EllipsisRasterLayer,
  EllipsisVectorLayer,
} from "react-leaflet-ellipsis";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { BiX } from "react-icons/bi";

function MapDisplay({
  lat,
  lon,
  loading,
  setLoading,
  selectedDatasetDetails,
  enableLayerChangeOpt,
  selectedModelName,
  setShowPopUp,
  showPopUp,
  setShowDirection,
  isAnalyticsLayer,
  setIsAnalyticsLayer,
  isSateliteLayer,
  setIsSateliteLayer,
}) {
  // const [isAnalyticsLayer, setIsAnalyticsLayer] = useState(true);
  // const [isSateliteLayer, setIsSateliteLayer] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [shouldFlyTo, setShouldFlyTo] = useState(false);
  const timeoutRef = useRef(null);

  function FlytoPosition({ val }) {
    const map = useMap();
    map.flyTo(val, 12);
    setShouldFlyTo(false);
  }

  useEffect(() => {
    // if (loading) {
    //   setTimeout(() => setLoading(false), 2000);
    // }
    setShouldFlyTo(true);
    setIsAnalyticsLayer(true);
    setIsSateliteLayer(false);
  }, [lat, lon]);

  useEffect(() => {
    if (!enableLayerChangeOpt) {
      setIsAnalyticsLayer(true);
      setIsSateliteLayer(false);
    }
  }, [enableLayerChangeOpt, selectedModelName]);

  useEffect(() => {
    if (isProcessing) {
      timeoutRef.current = setTimeout(() => {
        setIsProcessing(false);
        setShowPopUp(false);
        setShowDirection(true);
      }, 5000);
    } else if (!isProcessing) {
      clearTimeout(timeoutRef.current);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [isProcessing]);

  useEffect(() => {
    if (showPopUp) {
      setTimeout(() => {
        // setShowPopUp(false);
        setIsProcessing(true);
      }, 3000);
    }
  }, [showPopUp]);

  return (
    <div className="map-container">
      {loading ? (
        <div className="loader">
          <ClipLoader color={"#ffffff"} loading={loading} size={150} />
        </div>
      ) : (
        <MapContainer
          center={[lat, lon]}
          zoom={13}
          scrollWheelZoom={true}
          className="map-display"
        >
          {shouldFlyTo && <FlytoPosition val={[lat, lon]} />}

          {/* <TileLayer
            url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.{ext}"
            minZoom={0}
            maxZoom={20}
            attribution='&copy; CNES, Distribution Airbus DS, © Airbus DS, © PlanetObserver (Contains Copernicus Data) | &copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            ext="jpg"
          /> */}

          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />

          <TileLayer
            noWrap={true}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
          />

          {showPopUp && !isProcessing && (
            <div className="pt-popup-box-msg">
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div>
                <div className="msg-processing">
                  <h3>Processing</h3>
                  {/* <BiX
                    onClick={() => {
                      setShowPopUp(false);
                    }}
                    style={{ fontSize: "26px" }}
                  /> */}
                </div>
                <p>
                  Please hold on! We're processing your Task. This may take a
                  moment.
                </p>
              </div>
            </div>
          )}
          {isProcessing && (
            <div className="pt-popup-box-msg">
              <img src="/Images/Vector.png" alt="unable to load "></img>
              <div>
                <div className="msg-processing">
                  <h3 style={{ fontWeight: "400" }}>Scheduled</h3>
                  <BiX
                    onClick={() => {
                      setShowPopUp(false);
                      setIsProcessing(false);
                      setShowDirection(true);
                    }}
                    style={{ fontSize: "26px" }}
                  />
                </div>
                <p>
                  Your task has been scheduled successfully. Please find the
                  status in the navbar
                </p>
              </div>
            </div>
          )}

          {/* {selectedDatasetDetails && enableLayerChangeOpt && (
            <div
              style={{
                position: "absolute",
                right: "1%",
                top: "2%",
                zIndex: "1000",
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "4px",
                width: "fit-content",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="layer_id"
                      onChange={() => {
                        setIsAnalyticsLayer(!isAnalyticsLayer);
                      }}
                      defaultChecked
                    />
                  }
                  label="Analytics"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="layer_input_id"
                      onChange={() => {
                        setIsSateliteLayer(!isSateliteLayer);
                      }}
                    />
                  }
                  label="Satelite Imagery"
                />
              </FormGroup>
            </div>
          )} */}

          {selectedModelName === "fire_detection" ? (
            <>
              {isAnalyticsLayer &&
                selectedDatasetDetails &&
                selectedDatasetDetails.layer_id !== "none" &&
                selectedDatasetDetails.timestamp_id !== "none" && (
                  <Pane name="h3" style={{ zIndex: 20000 }}>
                    <EllipsisVectorLayer
                      pathId={selectedDatasetDetails.layer_id}
                      pane="h3"
                      timestampId={selectedDatasetDetails.timestamp_id}
                    />
                  </Pane>
                )}
              {isSateliteLayer &&
                selectedDatasetDetails &&
                selectedDatasetDetails.layer_id_input !== "none" &&
                selectedDatasetDetails.timestamp_id_input !== "none" && (
                  <Pane name="h4" style={{ zIndex: 10000 }}>
                    <EllipsisRasterLayer
                      styleId={selectedDatasetDetails.style_id}
                      pathId={selectedDatasetDetails.layer_id_input}
                      timestampId={selectedDatasetDetails.timestamp_id_input}
                      token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMzgzNDBhYTEtNTJjMS00Zjg3LThjYTItYzgyYjI5NDU2YmM5IiwiaWF0IjoxNjg2NTc1NTI1LCJleHAiOjE2ODY2NjE5MjV9.lYKKpCken3WGoOcfIW7lkX81V5PRf_x6JgbHfQRSGI0"
                    />
                  </Pane>
                )}
            </>
          ) : (
            <>
              {isSateliteLayer &&
                selectedDatasetDetails &&
                selectedDatasetDetails.layer_id_input !== "none" &&
                selectedDatasetDetails.timestamp_id_input !== "none" && (
                  <Pane name="h2" style={{ zIndex: 10000 }}>
                    <EllipsisRasterLayer
                      styleId={selectedDatasetDetails.style_id}
                      pathId={selectedDatasetDetails.layer_id_input}
                      timestampId={selectedDatasetDetails.timestamp_id_input}
                      token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMzgzNDBhYTEtNTJjMS00Zjg3LThjYTItYzgyYjI5NDU2YmM5IiwiaWF0IjoxNjg2NTc1NTI1LCJleHAiOjE2ODY2NjE5MjV9.lYKKpCken3WGoOcfIW7lkX81V5PRf_x6JgbHfQRSGI0"
                    />
                  </Pane>
                )}

              {isAnalyticsLayer &&
                selectedDatasetDetails &&
                selectedDatasetDetails.layer_id !== "none" &&
                selectedDatasetDetails.timestamp_id !== "none" && (
                  <Pane name="h1" style={{ zIndex: 20000 }}>
                    <EllipsisRasterLayer
                      styleId={selectedDatasetDetails.style_id}
                      pathId={selectedDatasetDetails.layer_id}
                      timestampId={selectedDatasetDetails.timestamp_id}
                      token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMzgzNDBhYTEtNTJjMS00Zjg3LThjYTItYzgyYjI5NDU2YmM5IiwiaWF0IjoxNjg2NTc1NTI1LCJleHAiOjE2ODY2NjE5MjV9.lYKKpCken3WGoOcfIW7lkX81V5PRf_x6JgbHfQRSGI0"
                    />
                  </Pane>
                )}
            </>
          )}

          {/* <Marker position={[lat, lon]} icon={divIcon}>
            <Popup>
              {`Location: ${lat}, ${lon}`} <br /> {selectedTimestamp}
            </Popup>
          </Marker> */}
        </MapContainer>
      )}
    </div>
  );
}

export default MapDisplay;
