import React, { useState, useEffect } from "react";
import "./CitySelector.css";
import InfoCardPanel from "../InfoCardPanel/InfoCardPanel";
import MapAndRadioPanel from "../MapAndRadioPanel/MapAndRadioPanel";
import { loadJSON, getCityLatLongForCity } from "../../utils/apicalls";
import AnalyticsBox from "../AnalyticsBox/AnalyticsBox";

const CitySelector = ({
  cityName,
  setShowDirection,
  setUpcomingPassDetails,
  getSelectedCityCardDetials,
  setShowStatus,
  upcomingPassDetails,
  filteredInfoCards,
  modelTitle,
  isStatusBtnVisible,
  checkStatus,
  showDirection,
  showStatus,
  upcomingPassTime,
  deliverImageTime,
  deliverInsightsTime,
}) => {
  const [selectedInfoCardTitle, setSelectedInfoCardTitle] = useState("");
  const [selectedCityLatLong, setSelectedCityLatLong] = useState(null);
  const [selectedInfoCardTimeStamps, setSelectedInfoCardTimeStamps] = useState(
    []
  );
  const [selectedInfoCardData, setSelectedInfoCardData] = useState(null);
  const [selectedDataSetDetails, setSelectedDataSetDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [enableLayerChangeOpt, setEnableLayerChangeOpt] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [cityLat, setCityLat] = useState("");
  const [cityLong, setCityLong] = useState("");
  const [isAnalyticsLayer, setIsAnalyticsLayer] = useState(true);
  const [isSateliteLayer, setIsSateliteLayer] = useState(false);
  const [openStatsBox, setOpenStatsBox] = useState(false);

  useEffect(() => {
    const fetchCitySelector = async (cityName) => {
      try {
        const jsonData1 = await loadJSON(
          `https://apidcit.skyserve.ai/v1/default/?city_name=${cityName}`
        );
        const latlong = getCityLatLongForCity(jsonData1, cityName);

        const card = jsonData1[0].models[0];
        handleCardClick(card, latlong.lat, latlong.long, cityName);
        setSelectedCityLatLong(latlong);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchCitySelector(cityName);
    setShowAlert(false);
    setSelectedInfoCardTimeStamps([]);
    setSelectedInfoCardData(null);
    setSelectedInfoCardTitle("");
    setEnableLayerChangeOpt(true);
  }, [cityName]);

  const fetchAlertData = async () => {
    try {
      const response = await fetch(
        `https://apidcit.skyserve.ai/v1/tasking_initial/?location_name=${cityName}&model_name=${selectedInfoCardTitle}`,
        {
          method: "GET",
        }
      );
      const ans = await response.json();
      const jsonData = Object.values(ans)[0];
      setSelectedInfoCardData(jsonData);
      return ans;
    } catch (error) {
      console.log(error);
    }
  };

  const handleTimeStampSelectedInMap = (timestamp) => {
    if (timestamp !== "alert") {
      var x = selectedDataSetDetails.timestamp.indexOf(timestamp);
      setSelectedInfoCardData(
        selectedDataSetDetails.timestamp_dict[x][timestamp]
      );
      setEnableLayerChangeOpt(true);
    } else {
      const flag = upcomingPassDetails.some((e) => e.city == cityName);
      if (flag) {
        fetchAlertData();
        setEnableLayerChangeOpt(false);
      }
    }
  };

  const handleCardClick = (card, lat, lng, cityName) => {
    const ind_latest_timestamp = card.timestamp.length - 1;
    const latest_timestamp = card.timestamp[ind_latest_timestamp];
    setSelectedInfoCardTimeStamps(card.timestamp);
    setSelectedInfoCardTitle(card.title);
    setSelectedInfoCardData(
      card.timestamp_dict[ind_latest_timestamp][latest_timestamp]
    );
    setSelectedDataSetDetails(card);
    getSelectedCityCardDetials(card, cityName);
    setCityLat(lat);
    setCityLong(lng);
    setShowPopUp(false);
    setShowStatus(false);
  };

  function getRandomValue(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    if (showPopUp) {
      var x = getRandomValue(40, 120);
      // var x = getRandomValue(5, 10);
      const newObject = {
        city: cityName,
        model_name: selectedDataSetDetails.title,
        timer: {
          upcoming_pass: x,
          image: getRandomValue(21600, 108000),
          insights: x + 12,
        },
      };
      setUpcomingPassDetails((prevData) => [...prevData, newObject]);
    }
  }, [showPopUp]);

  if (loading) {
    return <div>Loading City Categories...</div>;
  }
  return (
    <div style={{ width: "100%" }}>
      <div className="content">
        <div className="left-side">
          <InfoCardPanel
            city={cityName}
            onCardClick={handleCardClick}
            upcomingPassDetails={upcomingPassDetails}
            filteredInfoCards={filteredInfoCards}
            modelTitle={modelTitle}
          />
        </div>

        <div className="right-side">
          <MapAndRadioPanel
            city_lat={cityLat}
            city_long={cityLong}
            selectedModelName={selectedInfoCardTitle}
            city={cityName}
            timestamps={selectedInfoCardTimeStamps}
            selected_dataset_details={selectedInfoCardData}
            selected_timestamp_handler={handleTimeStampSelectedInMap}
            setShowPopUp={setShowPopUp}
            showPopUp={showPopUp}
            upcomingPassDetails={upcomingPassDetails}
            enableLayerChangeOpt={enableLayerChangeOpt}
            setShowDirection={setShowDirection}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            modelTitle={modelTitle}
            isStatusBtnVisible={isStatusBtnVisible}
            checkStatus={checkStatus}
            showDirection={showDirection}
            showStatus={showStatus}
            upcomingPassTime={upcomingPassTime}
            setShowStatus={setShowStatus}
            deliverImageTime={deliverImageTime}
            deliverInsightsTime={deliverInsightsTime}
            isAnalyticsLayer={isAnalyticsLayer}
            setIsAnalyticsLayer={setIsAnalyticsLayer}
            isSateliteLayer={isSateliteLayer}
            setIsSateliteLayer={setIsSateliteLayer}
          />
        </div>
        {selectedInfoCardData && enableLayerChangeOpt && (
          <div className="icons-statsBox">
            <div className="right-most-nav">
              <div
                className={`analytics-layer ${
                  isAnalyticsLayer && "layer-activated"
                }`}
                onClick={() => {
                  setIsAnalyticsLayer(!isAnalyticsLayer);
                }}
              >
                <span className="material-symbols-outlined">analytics</span>
              </div>
              <div
                className={`satelite-layer ${
                  isSateliteLayer && "layer-activated"
                }`}
                onClick={() => {
                  setIsSateliteLayer(!isSateliteLayer);
                }}
              >
                <span className="material-symbols-outlined">layers</span>
              </div>
              {/* <div
                className={`analytics-layer ${
                  openStatsBox && "layer-activated"
                }`}
                onClick={() => {
                  setOpenStatsBox(!openStatsBox);
                }}
              >
                <span class="material-symbols-outlined">info</span>
              </div> */}
            </div>
            {/* {openStatsBox && <AnalyticsBox setOpenStatsBox={setOpenStatsBox} />} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default CitySelector;
