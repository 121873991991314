import React, { useState, useEffect } from "react";
import "./MapAndRadioPanel.css";
import MapDisplay from "../MapDisplay/MapDisplay";
import RadioButtonList from "../RadioButtonList/RadioButtonList";
import { BsX, BsXCircle } from "react-icons/bs";
import AnalyticsBox from "../AnalyticsBox/AnalyticsBox";

function MapAndRadioPanel({
  city_lat,
  city_long,
  selectedModelName,
  city,
  timestamps,
  selected_dataset_details,
  selected_timestamp_handler,
  setShowPopUp,
  showPopUp,
  upcomingPassDetails,
  enableLayerChangeOpt,
  setShowDirection,
  showAlert,
  setShowAlert,
  modelTitle,
  isStatusBtnVisible,
  checkStatus,
  showDirection,
  showStatus,
  upcomingPassTime,
  setShowStatus,
  deliverImageTime,
  deliverInsightsTime,
  isAnalyticsLayer,
  setIsAnalyticsLayer,
  isSateliteLayer,
  setIsSateliteLayer,
}) {
  return (
    <div className="map-and-radio-panel">
      <div className="main-nav">
        <h3
          style={{
            background: "#212121",
            padding: "10px",
            borderRadius: "4px",
            color: "gold",
          }}
        >
          {city}
        </h3>
        {isStatusBtnVisible && (
          <div className="status-btn" onClick={checkStatus}>
            Status
          </div>
        )}
        {showDirection && (
          <div className="popup arrow-top">
            <div className="popup-wrapper">
              <p>Click here to see the status</p>
              <div
                className="status_popup_close_btn"
                onClick={() => {
                  setShowDirection(false);
                }}
              >
                Close
              </div>
            </div>
          </div>
        )}
        {showStatus && (
          <div className="popup-black arrow-top-black">
            <div className="popup-wrapper-black">
              <div>Upcoming Pass</div>
              <div
                className="status-cls-btn"
                onClick={() => setShowStatus(false)}
              >
                <BsX />
              </div>
              {upcomingPassTime ? (
                <div style={{ fontWeight: "bold" }}>
                  {" "}
                  {upcomingPassTime.hours < 10 && 0}
                  {upcomingPassTime.hours} <span>h</span> :
                  {upcomingPassTime.minutes < 10 && 0}
                  {upcomingPassTime.minutes} <span>m</span> :
                  {upcomingPassTime.seconds < 10 && 0}
                  {upcomingPassTime.seconds}
                  <span> s</span>{" "}
                </div>
              ) : (
                <div style={{ fontWeight: "bold" }}>
                  {" "}
                  00 <span>h</span> : 00 <span>m</span> : 00
                  <span> s</span>{" "}
                </div>
              )}
              <div>Delivery</div>
              <div>
                <div className="image-insights">
                  <div>
                    <p>Insights</p>
                    {deliverInsightsTime ? (
                      <p style={{ fontWeight: "bold" }}>
                        {" "}
                        {deliverInsightsTime.hours < 10 && 0}
                        {deliverInsightsTime.hours} <span>h</span> :
                        {deliverInsightsTime.minutes < 10 && 0}
                        {deliverInsightsTime.minutes} <span>m</span> :
                        {deliverInsightsTime.seconds < 10 && 0}
                        {deliverInsightsTime.seconds}
                        <span> s</span>{" "}
                      </p>
                    ) : (
                      <p style={{ fontWeight: "bold" }}>
                        {" "}
                        00 <span>h</span> : 00 <span>m</span> : 00
                        <span> s</span>{" "}
                      </p>
                    )}
                  </div>
                  <div>
                    <p>Image</p>
                    {deliverImageTime ? (
                      <p style={{ fontWeight: "bold" }}>
                        {" "}
                        {deliverImageTime.hours < 10 && 0}
                        {deliverImageTime.hours} <span>h</span> :
                        {deliverImageTime.minutes < 10 && 0}
                        {deliverImageTime.minutes} <span>m</span> :
                        {deliverImageTime.seconds < 10 && 0}
                        {deliverImageTime.seconds}
                        <span> s</span>{" "}
                      </p>
                    ) : (
                      <p style={{ fontWeight: "bold" }}>
                        {" "}
                        00 <span>h</span> : 00 <span>m</span> : 00
                        <span> s</span>{" "}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <AnalyticsBox></AnalyticsBox> */}
      <MapDisplay
        lat={city_lat}
        lon={city_long}
        selectedDatasetDetails={selected_dataset_details}
        enableLayerChangeOpt={enableLayerChangeOpt}
        selectedModelName={selectedModelName}
        setShowPopUp={setShowPopUp}
        showPopUp={showPopUp}
        setShowDirection={setShowDirection}
        isAnalyticsLayer={isAnalyticsLayer}
        setIsAnalyticsLayer={setIsAnalyticsLayer}
        isSateliteLayer={isSateliteLayer}
        setIsSateliteLayer={setIsSateliteLayer}
      />
      {timestamps.length > 0 && (
        <RadioButtonList
          timestamps={timestamps}
          onSelectTimestamp={selected_timestamp_handler}
          setShowPopUp={setShowPopUp}
          showPopUp={showPopUp}
          upcomingPassDetails={upcomingPassDetails}
          selectedModelName={selectedModelName}
          city={city}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}
    </div>
  );
}

export default MapAndRadioPanel;
